import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik, Field, FormikProvider } from 'formik';
import clsx from 'clsx';
import { DateTime } from 'luxon';

import { Button } from '../../../../../atoms/button';
import { TextInput } from '../../../../../atoms/text-input';
import { CallUs } from '../../../../../atoms/call-us';
import { SidebarHeader } from '../../../../../atoms/sidebar-header';
import { PBSLink } from '../../../../../atoms/link';

import {
	sleep,
	sendTrackingData,
	getCookie,
	// pageUrlFomater,
} from '../../../../../../utils';
import {
	validateString,
	validateEmail,
	validatePhoneNumber,
} from '../../../../../../utils/marketo';

import CountdownTimer from '../../../../../glu/molecules/countdown-timer';
import useCountdown from '../../../../../../hooks/useCountdown';
import { useFormValidation } from '../../../../../../hooks/form-validation';

import { useLocation } from '@reach/router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSalesforceApiCA } from '../../../../../../hooks/salesforce-ca';
import { useSalesforceAzureApi } from '../../../../../../hooks/salesforce-azure-ca';

// Helper function to format phone number to E.164
const formatPhoneToE164 = (phoneNumber, countryCode = '1') => {
	const digits = phoneNumber.replace(/\D/g, '');
	if (phoneNumber.startsWith('+')) {
		return phoneNumber;
	}
	if (digits.startsWith(countryCode)) {
		return `+${digits}`;
	}
	return `+${countryCode}${digits}`;
};

const WebinarSignupFormCA = ({
	dateAndTime,
	setFormResponse,
	timeZone,
	formId,
	setReference,
}) => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { fetchSalesforceCA } = useSalesforceApiCA();
	const { fetchSalesforceAzure } = useSalesforceAzureApi();
	const [submitting, setSubmitting] = useState(false);

	const initialValidations = {
		FirstName: { isValid: true },
		LastName: { isValid: true },
		Email: { isValid: true },
		Phone: { isValid: true },
		Company: { isValid: true },
	};

	const timeZonedDateAndTime = DateTime.fromISO(dateAndTime, {
		zone: timeZone,
	});

	// console.log(dateAndTime);

	const [formattedDate, setFormattedDate] = useState('');

    // Send Date To Salesforce
	useEffect(() => {
		// The timestamp in milliseconds
		const timestamp = dateAndTime;

		// Create a new Date object from the timestamp
		const date = new Date(timestamp);

		// Extract the year, month, and day
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
		const day = String(date.getDate()).padStart(2, '0'); // Ensure the day is always two digits

		// Combine them into the yyyy-mm-dd format
		const formatted = `${year}-${month}-${day}`;

		// Set the formatted date in the state
		setFormattedDate(formatted);
	}, [formattedDate]); // Empty dependency array ensures this runs only once after initial render

	//   console.log(formattedDate);

	const [isError, setIsError] = useState(false);
	const { validations, validateForm } = useFormValidation(initialValidations);

	const title = 'Reserve your spot';

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: title,
		});
	};

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		Company: '',
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: true,
	});

	const locationUrl = useLocation();
    const pageUrlWithSource = locationUrl.href;
    // console.log(pageUrlWithSource);
    
	// const pageUrl = pageUrlFomater(locationUrl);
    // console.log(pageUrl);
	const submitHandler = async (event) => {
		// event.preventDefault();

		// const { formEntries, isValid } = validateForm(event.currentTarget);

		// if (!isValid) return;

		// const payloadData = {
		// 	...initialValues,
		// 	FirstName: formEntries.FirstName,
		// 	LastName: formEntries.LastName,
		// 	Phone: formEntries.Phone,
		// 	Email: formEntries.Email,
		// 	Company: formEntries.Company,
		// 	PersonSource: 'Website - Confirm Webinar Web Lead',
		// };

		// await sleep(500);
		// handleClickTracking();
		// useMarketoFormSubmit(payloadData, formId, MktoForms2Instances)
		// 	.then(() => {
		// 		formik.setStatus('submitted');
		// 		setFormResponse(true);
		// 	})
		// 	.catch(() => {
		// 		formik.setStatus('error');
		// 		setTechnicalError(true);
		// 	})
		// 	.finally(() => {
		// 		handleClickTracking();
		// 	});

		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) return;

		setSubmitting(true);

		// Format phone number to E.164
		const formattedPhone = formatPhoneToE164(formEntries.Phone);

		// Single dataLayer push with all necessary data
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'ec_form_submit',
				user_data: {
					email: formEntries.Email,
					phone: formattedPhone,
				},
			});
		}

		const payloadData = {
			firstName: formEntries.FirstName,
			lastName: formEntries.LastName,
			company: formEntries.Company,
			phoneNumber: formEntries.Phone,
			email: formEntries.Email,
			leadSource: 'Events',
			leadSourceDetails: 'Web Form',
			selectedDate: formattedDate,
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl: pageUrlWithSource,
			utmCampaign: getCookie('utm_campaign'),
			utmMedium: getCookie('utm_medium'),
			utmSource: getCookie('utm_source'),
			utmTerm: getCookie('utm_term'),
		};

		const response = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.email);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setFormResponse(true);
				setReference(response.body.identifier);
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	const { hasExpired } = useCountdown(timeZonedDateAndTime);

	// useEffect(() => {
	// 	const loadForm = () =>
	// 		loadScript(
	// 			'https://app-ab31.marketo.com/js/forms2/js/forms2.min.js'
	// 		)
	// 			.then(() => {
	// 				MktoForms2Instances.get(
	// 					'https://app-ab31.marketo.com'
	// 				).loadForm(
	// 					'https://app-ab31.marketo.com',
	// 					'544-LUT-177',
	// 					formId
	// 				);
	// 			})
	// 			.catch(() => {});

	// 	if (window.requestIdleCallback) {
	// 		window.requestIdleCallback(loadForm);
	// 	} else {
	// 		setTimeout(loadForm);
	// 	}

	// 	setReferralCookie(getCookie('utm_source'));
	// 	setKeywordCookie(getCookie('utm_term'));
	// 	setMediumCookie(getCookie('utm_medium'));
	// 	setCampaignCookie(getCookie('utm_campaign'));
	// 	setGclidCookie(getCookie('gclid'));
	// 	setCampaignLastCookie(getCookie('utm_campaign_last'));
	// 	setContentLastCookie(getCookie('utm_content_last'));
	// 	setMediumLastCookie(getCookie('utm_medium_last'));
	// 	setSourceLastCookie(getCookie('utm_source_last'));
	// 	setTermLastCookie(getCookie('utm_term_last'));
	// 	setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	// }, [MktoForms2Instances, formId]);

	useEffect(() => {
		if (!validations.FirstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.LastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.Email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (!validations.Phone.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations.Company.isValid && companyRef.current) {
			return companyRef.current.focus();
		}
	}, [validations]);

	return isError ? (
		<div className="px-4 pb-10 bg-white">
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</div>
	) : (
		<FormikProvider value={formik}>
			<form
				data-formid={formId}
				data-forminstance="one"
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto overflow-hidden border rounded-sm shadow-lg grow border-brand-pale-500"
			>
				<div className="flex flex-col p-4 lg:p-8 lg:flex-row bg-brand-blue-400">
					<div className="w-full text-white">
						<CountdownTimer targetDate={timeZonedDateAndTime} />
					</div>
				</div>
				<div className="flex flex-col grow">
					<fieldset
						className="flex flex-col px-4 pt-5 bg-white lg:pt-10 lg:px-8 grow"
						disabled={submitting}
					>
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="FirstName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="FirstName"
											className="text-brand-blue-400 lg:text-base-f font-lexend-medium"
										>
											First name&#42;
										</label>
										<TextInput
											id="FirstName"
											name="FirstName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={firstNameRef}
											showValidation={
												!validations.FirstName
													.isValid &&
												!firstNameRef.current.value
											}
											validationMessage={
												!validations.FirstName.isValid
													? 'Please enter your first name'
													: null
											}
											{...field}
											space={false}
											placeholder="Jane"
											validateOnBlur
										/>
									</div>
								)}
							</Field>

							<Field name="LastName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="LastName"
											className="text-brand-blue-400 lg:text-base-f font-lexend-medium"
										>
											Last name&#42;
										</label>
										<TextInput
											id="LastName"
											name="LastName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={lastNameRef}
											showValidation={
												!validations.LastName.isValid &&
												!lastNameRef.current.value
											}
											validationMessage={
												!validations.LastName.isValid
													? 'Please enter your last name'
													: null
											}
											{...field}
											space={false}
											placeholder="Smith"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>
						<Field name="Company" validate={validateString}>
							{({ field }) => (
								<div>
									<label
										htmlFor="Company"
										className="text-brand-blue-400 lg:text-base-f font-lexend-medium"
									>
										Company&#42;
									</label>
									<TextInput
										id="Company"
										name="Company"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={companyRef}
										showValidation={
											!validations.Company.isValid &&
											!companyRef.current.value
										}
										validationMessage={
											!validations.Company.isValid
												? 'Please enter your company'
												: null
										}
										{...field}
										space={false}
										placeholder="Company LTD"
										validateOnBlur
									/>
								</div>
							)}
						</Field>

						<Field name="Email" validate={validateEmail}>
							{({ field }) => (
								<div>
									<label
										htmlFor="Email"
										className="text-brand-blue-400 lg:text-base-f font-lexend-medium"
									>
										Email&#42;
									</label>
									<TextInput
										id="Email"
										type="email"
										name="Email"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={emailRef}
										showValidation={
											!validations.Email.isValid &&
											!emailRef.current.value
										}
										validationMessage="Please enter your email address"
										{...field}
										space={false}
										placeholder="jane.smith@gmail.com"
										validateOnBlur
									/>
								</div>
							)}
						</Field>

						<Field name="Phone" validate={validatePhoneNumber}>
							{({ field }) => (
								<div>
									<label
										htmlFor="Phone"
										className="text-brand-blue-400 lg:text-base-f font-lexend-medium"
									>
										Phone&#42;
									</label>
									<TextInput
										id="Phone"
										name="Phone"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={phoneNumberRef}
										showValidation={
											!validations.Phone.isValid &&
											!phoneNumberRef.current.value
										}
										validationMessage="Please enter a phone number"
										{...field}
										space={false}
										type="tel"
										validateOnBlur
									/>
								</div>
							)}
						</Field>
					</fieldset>
				</div>

				<div className="flex flex-col items-center px-8 pt-3 pb-4 bg-white lg:pb-12 lg:flex-row lg:justify-between">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								formik.isSubmitting
									? 'justify-center loading'
									: null
							)}
							disabled={hasExpired}
						>
							<span
								className={clsx(
									formik.isSubmitting ? 'invisible' : null
								)}
							>
								{title}
							</span>
							{formik.isSubmitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
					<p className="px-4 pt-4 text-center bg-white lg:text-base-f lg:pt-0">
						View our{' '}
						<PBSLink
							to="https://www.peninsulagrouplimited.com/ca/privacy-policy/"
							variant="Link"
							size="Small"
						>
							privacy notice
						</PBSLink>
						.
					</p>
				</div>
			</form>
		</FormikProvider>
	);
};

WebinarSignupFormCA.defaultProps = {
	setFormResponse: undefined,
	setConsentResponse: undefined,
	formId: 0,
};

WebinarSignupFormCA.propTypes = {
	setConsentResponse: PropTypes.func,
	dateAndTime: PropTypes.string.isRequired,
	timeZone: PropTypes.string.isRequired,
	setFormResponse: PropTypes.func,
	formId: PropTypes.number,
};

export { WebinarSignupFormCA };

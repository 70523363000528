// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import { DateTime } from 'luxon';
import {
	StructuredText,
	renderNodeRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isHeading, isListItem, isList } from 'datocms-structured-text-utils';
// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLocation } from '@reach/router';
import clsx from 'clsx';
import PageSection from '../components/glu/organisms/page-section';
import { WebinarSignupForm } from '../components/glu/organisms/webinar-sign-up-form';
// import { WebinarSignupFormCA } from '../components/glu/organisms/webinar-sign-up-form-ca';
import { WebinarSignupFormCA } from '../components/organisms/ca/forms/salesforce/webinar-signup';
import TickIcon from '../assets/tick.inline.svg';
import { EventDate } from '../components/atoms/event-date';
import { ResourceHeader } from '../components/molecules/resource-header';
import LiveSessionCard from '../components/glu/molecules/live-session-card';
import { Head } from '../components/templates/head';
import {
	getOrganizationSeoSchema,
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { getBreadcrumLocale, pageTypes } from '../utils/url-helpers';
import { DefaultLayout } from '../layouts/default';
import { WebinarMoreInfoForm } from '../components/glu/organisms/webinar-more-info-form';
import { WebinarMoreInfoFormCA } from '../components/glu/organisms/webinar-more-info-form-ca';
import useIsMobile from '../hooks/mobile';
import { AddToCalendar } from '../components/atoms/add-to-calendar';
import { HrefLang } from '../components/templates/hrefLang';
import { LOCALE_PATHS, buildLink } from '../utils/locale';
import { convertTo12HourFormat, formatAMPM } from '../utils/date-formatter';

const LiveWebinar = ({ data, location }) => {
	const {
		id,
		dateAndTime,
		mainLogo,
		title,
		speaker,
		category,
		fullContent,
		timeEnd,
		timeZone,
		sessions,
		customMkFormId,
		richTextField,
		showQuestionOnTopic,
	} = data.datoCmsLiveWebinar;
	const [formResponse1, setFormResponse1] = useState(false);
	const [formResponse2, setFormResponse2] = useState(false);
	const date = format(new Date(dateAndTime), 'MMM d, yyyy');
	const time = `${format(new Date(dateAndTime), 'h:mm')} - ${timeEnd}`;
	const parsedStartDateTime = DateTime.fromISO(dateAndTime, {
		zone: timeZone,
	});
	const timeZoneMap = {
		'America/Toronto': 'ET',
		'America/Vancouver': 'PT',
	};

	const transformTime = (timeToCut) => {
		if (timeToCut.startsWith('0')) {
			return timeToCut.slice(1);
		}
		return timeToCut;
	};
	const formatString = 'h:mm a';
	const startDateTimeAsString = parsedStartDateTime.toFormat(formatString);
	const timeZonedTime = `${startDateTimeAsString} - ${transformTime(
		timeEnd
	)} ${timeZoneMap[timeZone]}`;
	const start = format(new Date(dateAndTime), 'MM/dd/yyyy');
	const endDate = format(new Date(dateAndTime), 'MM/dd/yyyy');
	const dateStringStart = `${start} ${formatAMPM(new Date(dateAndTime))}`;
	const time12hrs = convertTo12HourFormat(
		timeEnd
			.replace(' PDT', '')
			.replace(' PST', '')
			.replace(' EDT', '')
			.replace(' EST', '')
	);
	const dateStringEnd = `${endDate} ${time12hrs}`;
	const dateNow = new Date();
	// create date and time for calendar
	const endDateAndTime = new Date(dateNow.setTime(Date.parse(dateStringEnd)))
		?.toISOString()
		.replace('.', '');
	const startDateAndTime = new Date(
		dateNow.setTime(Date.parse(dateStringStart))
	)
		.toISOString()
		.replace('.', '');

	const resourceHeaderContent = {
		id,
		title,
		speaker,
		categories: [{ name: category }],
	};

	const { locale } = data.datoCmsLiveWebinar;

	const startDateTime = DateTime.fromISO(dateAndTime, {
		zone: timeZone,
	});

	const [reference, setReference] = useState('');

	// Redirects to webinars home page after webinar ends
	useEffect(() => {
		const now = DateTime.now().setZone(timeZone);
		const differenceInMinutes = now.diff(startDateTime, 'minutes').minutes;

		if (differenceInMinutes > 60 && locale === 'en-CA') {
			navigate('/ca/webinars');
		}
	}, [startDateTime, timeZone, locale]);

	// const formId = customMkFormId;
	const currentSiteUrl = useLocation();
	const structureRef = useRef();
	const metaContent = {
		noIndex: data.datoCmsLiveWebinar.noIndex,
		noFollow: data.datoCmsLiveWebinar.noFollow,
		metaInformation: data.datoCmsLiveWebinar.metaTags,
		fallbackTitle: data.datoCmsLiveWebinar.eLearningTitle,
		fallbackDescription: '',
		canonicalUrl: data.datoCmsLiveWebinar?.canonicalUrl || location?.href,
	};
	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			data: data.datoCmsLiveWebinar,
			pageType: pageTypes.liveWebinar,
			location,
		}),
		image: getImageSeoSchema(mainLogo),
	};
	const scrollRef = useRef();
	const isMobile = useIsMobile();

	const scroll = () =>
		setTimeout(() => {
			window.scrollTo({
				top: scrollRef?.current?.offsetTop,
				left: 0,
				behavior: 'smooth',
			});
		}, 500);

	useEffect(() => {
		if (formResponse1 && !formResponse2 && !isMobile) {
			scroll();
		}
	}, [formResponse1, formResponse2, isMobile]);
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsLiveWebinar.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<PageSection>
				<div className="flex flex-col gap-20 lg:flex-row">
					<div className="w-full lg:w-1/2">
						<p className="w-full mb-5">
							{locale !== 'en-CA' ? (
								<a
									href={buildLink(locale, 'webinars')}
									className="underline"
								>
									Webcasts
								</a>
							) : (
								<a
									href={buildLink(locale, 'webinars')}
									className="underline"
								>
									Webinars
								</a>
							)}
							<span className="mx-4">/</span>
							{title}
						</p>
						{locale === 'en-CA' ? (
							<EventDate date={date} time={timeZonedTime} />
						) : (
							<EventDate date={date} time={time} />
						)}
						<ResourceHeader
							className="!px-0"
							content={resourceHeaderContent}
							variant="Event"
						/>
					</div>
					<div className="relative w-full px-2 pb-5 lg:w-1/2">
						{formResponse1 ? (
							<div className="flex flex-col overflow-hidden rounded-sm">
								<h3 className="w-full p-4 text-2xl text-white bg-brand-blue-400 lg:p-8">
									<TickIcon
										className="inline-block w-5 h-auto mr-4 text-white shrink-0"
										aria-hidden="true"
									/>{' '}
									Your spot has been secured
								</h3>
								<div className="p-4 bg-white lg:p-8">
									<p className="mb-5 font-bold">
										Thank you for registering your spot to
										our webcast{' '}
										<span className="text-brand-red-400">
											{title}
										</span>
									</p>
									<p className="pb-5 lg:pb-0">
										A reminder of the start date has been
										sent out to your registered email with
										the details of the webcast.
									</p>
									{formResponse2 && (
										<>
											<p className="py-5">
												Response received. Thank you!
											</p>
											{locale !== 'en-CA' && (
												<AddToCalendar
													shortDescription={
														structureRef.current
															?.innerHTML
													}
													className="mb-5 w-fit"
													event={{
														startDateTime:
															startDateAndTime,
														endDateTime:
															endDateAndTime,
														title: `PENINSULA LIVE WEBCAST: ${title}`,
														description:
															LOCALE_PATHS[
																locale
															] === 'ie'
																? `PENINSULA LIVE WEBCAST`
																: 'Live Webcast',
														location:
															LOCALE_PATHS[
																locale
															] === 'ie'
																? currentSiteUrl.href
																: 'Online. Check your email for details.',
													}}
												/>
											)}
										</>
									)}
								</div>
							</div>
						) : (
							<div className="top-0 lg:absolute">
								{locale === 'en-CA' ? (
									<WebinarSignupFormCA
										dateAndTime={dateAndTime}
										pageUrl={
											typeof window !== 'undefined'
												? window.location.href
												: ''
										}
										setFormResponse={setFormResponse1}
										formId={4777}
										timeZone={timeZone}
										setReference={setReference}
									/>
								) : (
									<WebinarSignupForm
										dateAndTime={dateAndTime}
										pageUrl={
											typeof window !== 'undefined'
												? window.location.href
												: ''
										}
										setFormResponse={setFormResponse1}
										formId={customMkFormId}
										richTextField={richTextField}
										showQuestionOnTopic={
											showQuestionOnTopic
										}
									/>
								)}
							</div>
						)}
						{formResponse1 && !formResponse2 && (
							<div className="p-4 bg-white lg:p-8">
								{locale === 'en-CA' ? (
									// <WebinarMoreInfoFormCA
									// 	dateAndTime={dateAndTime}
									// 	pageUrl={
									// 		typeof window !== 'undefined'
									// 			? window.location.href
									// 			: ''
									// 	}
									// 	setFormResponse={setFormResponse2}
									// 	reference={reference}
									// />
									<p></p>
								) : (
									<WebinarMoreInfoForm
										dateAndTime={dateAndTime}
										pageUrl={
											typeof window !== 'undefined'
												? window.location.href
												: ''
										}
										setFormResponse={setFormResponse2}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			</PageSection>
			<PageSection theme="White">
				<div
					ref={structureRef}
					className={clsx(
						'w-full lg:w-1/2 ',
						richTextField && showQuestionOnTopic
							? 'min-h-[500px]'
							: 'min-h-[300px]'
					)}
				>
					<h2 className="mb-5 text-3xl font-bold">
						About this upcoming session
					</h2>
					{locale !== 'en-CA' ? (
						<StructuredText
							data={fullContent.value}
							customNodeRules={[
								renderNodeRule(
									isHeading,
									({ node, children, key }) => {
										const HeadingTag = `h${node.level}`;
										return (
											<HeadingTag
												className="mb-5 text-2xl font-bold lg:text-3xl font-lexend-regular"
												key={key}
											>
												{children}
											</HeadingTag>
										);
									}
								),
								renderNodeRule(
									isListItem,
									({ children, key }) => (
										<li
											className="flex items-center mt-5 mb-4 text-sm last-of-type:mb-0 font-lexend-light"
											key={key}
										>
											<TickIcon
												className="w-3 h-auto mr-4 text-blue-400 shrink-0"
												aria-hidden="true"
											/>

											{children}
										</li>
									)
								),
							]}
						/>
					) : (
						<StructuredText
							data={fullContent.value}
							customNodeRules={[
								// Render headings
								renderNodeRule(
									isHeading,
									({ node, children, key }) => {
										const HeadingTag = `h${node.level}`;
										return (
											<HeadingTag
												className="mb-5 text-2xl font-bold lg:text-3xl font-lexend-regular"
												key={key}
											>
												{children}
											</HeadingTag>
										);
									}
								),
								renderNodeRule(
									isListItem,
									({ children, key }) => (
										<li
											className="flex items-center mt-5 mb-4 text-sm last-of-type:mb-0 font-lexend-light"
											key={key}
										>
											<TickIcon
												className="w-3 h-auto mr-4 text-blue-400 shrink-0"
												aria-hidden="true"
											/>
											{children}
										</li>
									)
								),

								renderNodeRule(isList, ({ children, key }) => (
									<ul className="mb-5" key={key}>
										{children}
									</ul>
								)),
							]}
						/>
					)}
				</div>
			</PageSection>

			{sessions?.length > 0 && (
				<PageSection logo={mainLogo} titleBlock="Similar live sessions">
					<div className="grid h-full grid-cols-1 gap-10 lg:grid-cols-3">
						{sessions.map((session) => (
							<div key={session.title}>
								<LiveSessionCard
									session={session}
									pbsWebinar
									locale={locale}
								/>
							</div>
						))}
					</div>
				</PageSection>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedLiveWebinar = ({ data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<LiveWebinar data={data} location={location} />
	</DefaultLayout>
);

export default WrappedLiveWebinar;

export const query = graphql`
	query LiveWebinar($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
		}
		datoCmsLiveWebinar(slug: { eq: $slug }, locale: { eq: $locale }) {
			id
			noIndex
			allSeoLocales: _allSlugLocales {
				locale
			}
			locale
			metaTags {
				title
				description
				image {
					url
				}
				twitterCard
			}
			mainLogo {
				gatsbyImageData(
					placeholder: DOMINANT_COLOR
					width: 216
					height: 156
				)
				alt
			}
			category
			title
			slug
			shortenedContent {
				value
			}
			fullContent {
				value
			}
			speaker {
				name
				image {
					alt
					url
					gatsbyImageData(width: 44, height: 44, layout: FIXED)
				}
				jobTitle
				linkedinUrl
				facebookUrl
				twitterUrl
				emailAddress
				bio {
					value
				}
			}
			dateAndTime
			timeZone
			timeEnd
			sessions {
				title
				timeEnd
				dateAndTime
				slug
				category
				shortenedContent {
					value
				}
			}

			customMkFormId
			richTextField
			showQuestionOnTopic
		}
	}
`;
